import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Container from '@/components/Container'
import Typography from '@/components/Typography'
import SectionWrapper from '@/components/SectionWrapper'
import { Container as CollapseContainer } from '@/components/Collapse/Collapse.styles'
import { PrimaryTitleContainer } from '../../components/PrimaryTitle/PrimaryTitle.styles'
import { Image } from '@/components/RichText/RichText.styles'

export const Wrapper = styled(SectionWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.sectionTheme.background};

  > ${Container} > ${PrimaryTitleContainer} + * {
    margin-top: 40px;
  }

  > ${Container} {
    max-width: 800px;
  }
`

export const Description = styled(Typography).attrs({
  variant: TextStyles['Small Paragraph Text'],
})<{ $sectionTheme?: string }>`
  text-align: left;
  color: ${({ theme, $sectionTheme }) =>
    $sectionTheme === 'Dark' ? theme.colors.white02 : theme.colors.black02};

  ${Image} {
    width: 100%;
    object-fit: contain;
  }
`

export const NestedListWrapper = styled.div<{ $sectionTheme?: string }>`
  max-width: 800px;

  &,
  > ${CollapseContainer} + ${CollapseContainer} {
    border-top: 1px solid
      ${({ theme, $sectionTheme }) =>
    $sectionTheme === 'Dark' ? theme.colors.white04 : theme.colors.black04};
  }

  svg path {
    fill: ${({ theme, $sectionTheme }) =>
    $sectionTheme === 'Dark' ? theme.colors.white02 : theme.colors.black02};
  }
`
